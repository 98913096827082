let CameraStartPosition = {
  x: 4.783029455119695,
  y: 4.941479378815085,
  z: 8.547468200563303,
  rx: -0.33731022655762694,
  ry: 0.48057706643672693,
  rz: 0.16073299420617898
}

let DoorPosition = {
  start: {
    x: null,
    y: null,
    z: null,
    rx: null,
    ry: null,
    rz: -2.007128748645229
  },
  stop: {
    x: null,
    y: null,
    z: null,
    rx: null,
    ry: null,
    rz: -0.3036873
  }
}

let DoorHandlePosition = {
  start: {
    x: null,
    y: null,
    z: null,
    rx: 0,
    ry: 0,
    rz: 0
  },
  position_0: {
    x: null,
    y: null,
    z: null,
    rx: -0.331613,
    ry: 0.7330383,
    rz: 0.1745329
  },
  stop: {
    x: null,
    y: null,
    z: null,
    rx: -1.37881,
    ry: 0.4014257,
    rz: -0.174533
  }
}

let CapPosition = {
  start: {
    x: null,
    y: null,
    z: null,
    rx: -1.5678310124,
    ry: null,
    rz: null
  },
  stop: {
    x: null,
    y: null,
    z: null,
    rx: -3.17876817,
    ry: null,
    rz: null
  }
}

let CapPillarPosition = {
  start: {
    x: null,
    y: null,
    z: null,
    rx: -0.0698132,
    ry: 0,
    rz: 0
  },
  stop: {
    x: null,
    y: null,
    z: null,
    rx: -1.955,
    ry: 0,
    rz: 0
  }
}

let CapPillarPistonsPosition = {
  start: {
    x: 0.5821385979652405,
    y: 5.621827125549316,
    z: 64.0560073852539,
    rx: null,
    ry: null,
    rz: null
  },
  stop: {
    x: 0.5821385979652405,
    y: 5.621827125549316,
    z: 70.5560073852539,
    rx: null,
    ry: null,
    rz: null
  }
}

let BoxDoorPosition = {
  start: {
    x: null,
    y: null,
    z: null,
    rx: 0,
    ry: 0,
    rz: -1.574482989939427
  },
  stop: {
    x: null,
    y: null,
    z: null,
    rx: 0,
    ry: 0,
    rz: 0.274482989939427
  }
}

export {
  CameraStartPosition,
  DoorPosition,
  DoorHandlePosition,
  CapPosition,
  CapPillarPosition,
  CapPillarPistonsPosition,
  BoxDoorPosition
}
