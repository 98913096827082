import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import * as THREE from 'three'

const app = createApp(App)
app.config.globalProperties.version = 1.0
app.config.globalProperties.d3 = {
  scene: new THREE.Scene(),
  renderer: new THREE.WebGLRenderer({
    alpha: true,
    antialias: true
  }),
  camera: new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 0.25, 30 )
}
app.use(store).mount("#app")
