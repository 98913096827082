<template>
  <StartBlock />
  <d3Canvas />
</template>

<script>
import StartBlock from './StartBlock.vue'
import d3Canvas from './d3Canvas.vue'
//import { LoaderTest } from '../functions/loader.js'

export default {
  name: 'Canvas',
  components: {
    StartBlock,
    d3Canvas
  },
  data() {
    return {
    }
  },
  props: {
  },
  mounted() {

  },
  methods: {
    //loader: LoaderTest
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
