const general = {
  namespaced: true,
  // initial state
  state: () => ({
    securekey: '3b94b39f02cd4c4d3130760b052558a147906c26e79bab421f5a0825d4c39f0d',
    isSecureMode: true,
    is3DLoaded : false,
    isDoorOpened : false,
    isHoodOpened : false,
    isBoxDoorOpened : false,
    isGlassRemoved : false,
    isFansBlockRemoved : false,
    isPowerBlockRemoved : false,
    mode : 3
  }),

  // mutations
  mutations: {
    change3DLoadingState(state, payload) {
      state.is3DLoaded = payload
    },
    changeDoorState(state, payload) {
      state.isDoorOpened = payload
    },
    changeHoodState(state, payload) {
      state.isHoodOpened = payload
    },
    changeBoxDoorState(state, payload) {
      state.isBoxDoorOpened = payload
    },
    changeGlassState(state, payload) {
      state.isGlassRemoved = payload
    },
    changeFansBlockState(state, payload) {
      state.isFansBlockRemoved = payload
    },
    changePowerBlockState(state, payload) {
      state.isPowerBlockRemoved = payload
    },
    changeModeState(state, payload) {
      state.mode = payload
    },
    changeSecureModeState(state, payload) {
      state.isSecureMode = payload
    }
  },

  // actions
  actions: {
    change3DLoadingState: async (context, payload) => {
      context.commit('change3DLoadingState', payload)
    },
    changeDoorState: async (context, payload) => {
      context.commit('changeDoorState', payload)
    },
    changeHoodState: async (context, payload) => {
      context.commit('changeHoodState', payload)
    },
    changeBoxDoorState: async (context, payload) => {
      context.commit('changeBoxDoorState', payload)
    },
    changeGlassState: async (context, payload) => {
      context.commit('changeGlassState', payload)
    },
    changeFansBlockState: async (context, payload) => {
      context.commit('changeFansBlockState', payload)
    },
    changePowerBlockState: async (context, payload) => {
      context.commit('changePowerBlockState', payload)
    },
    changeModeState: async (context, payload) => {
      context.commit('changeModeState', payload)
    },
    changeSecureModeState: async (context, payload) => {
      context.commit('changeSecureModeState', payload)
    }
  },

  // getters
  getters: {
    is3DLoaded : state => () => state.is3DLoaded,
    isDoorOpened : state => () => state.isDoorOpened,
    isHoodOpened : state => () => state.isHoodOpened,
    isBoxDoorOpened : state => () => state.isBoxDoorOpened,
    isGlassRemoved : state => () => state.isGlassRemoved,
    isFansBlockRemoved : state => () => state.isFansBlockRemoved,
    isPowerBlockRemoved : state => () => state.isPowerBlockRemoved,
    mode : state => () => state.mode,
    securekey : state => () => state.securekey,
    isSecureMode : state => () => state.isSecureMode,
  }
}

export {
  general
}
