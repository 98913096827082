<template>
  <div  id='content'>
    <Canvas />
  </div>
</template>

<script>
import Canvas from './components/Canvas.vue'

export default {
  name: 'App',
  data() {
    return {}
  },
  components: {
    Canvas
  },
  mounted() {
    this.$store.watch(this.$store.getters['general/is3DLoaded'], (isload) => {
      let content = document.getElementById('content')
      if (isload) content.classList.add("loaded")
    })
  }
}
</script>

<style>
@font-face {
  font-family: Popins; /* Имя шрифта */
  src: url(./assets/fonts/poppins/Poppins-Light.ttf); /* Путь к файлу со шрифтом */
}

@font-face {
  font-family: ITCAvantGarde-Bold; /* Имя шрифта */
  src: url(./assets/fonts/ITCAvantGardeStd/ITCAvantGardeStd-Bold.woff); /* Путь к файлу со шрифтом */
}

@font-face {
  font-family: ITCAvantGarde-Bk; /* Имя шрифта */
  src: url(./assets/fonts/ITCAvantGardeStd/ITCAvantGardeStd-Bk.woff); /* Путь к файлу со шрифтом */
}

body, html {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

* {
  font-family: Popins;
}

.form-line-active input:focus, .form-line-active textarea:focus {
border: 1px solid #00447C !important;
border-radius: 0;
box-shadow: 0 0 3px #00447C !important;
-moz-box-shadow: 0 0 3px #00447C !important;
-webkit-box-shadow: 0 0 3px #00447C !important;
}

input:focus, textarea:focus, select:focus {
outline-offset: 0px !important;
outline: none !important;
}

#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #DDDEDE;
}

#content.loaded {
  background: linear-gradient(#e2e2e2, #ffffff);
}
</style>
